<form
	class="login-form"
	[formGroup]="loginForm"
	(ngSubmit)="onSubmit()"
>
	<div class="login-form__wrapper">
		<header class="login-form__header">
			<img
				src="/assets/login-logo.jpeg"
				alt=""
			/>
			<div class="login-form__heading">
				<h1>Sign in</h1>
				with your Pangea account
			</div>
		</header>

		<div class="login-form__content">
			<div class="login-form__labels">
				<pngc-mat-form-field label="Username">
					<input
						matInput
						formControlName="username"
						type="text"
						autocomplete="username"
					/>
				</pngc-mat-form-field>

				<pngc-mat-form-field
					label="Password"
					[hasSuffix]="true"
				>
					<input
						matInput
						formControlName="password"
						[type]="showPassword ? 'text' : 'password'"
						autocomplete="current-password"
					/>
					<button
						ngProjectAs="suffix"
						mat-icon-button
						type="button"
						(click)="showPassword = !showPassword"
					>
						<mat-icon
							*ngIf="showPassword"
							svgIcon="visibility_eye_off"
						></mat-icon>
						<mat-icon
							*ngIf="!showPassword"
							svgIcon="visibility_eye"
						></mat-icon>
					</button>
				</pngc-mat-form-field>
			</div>

			<pngc-form-error-wrapper [form]="loginForm" />
		</div>

		<button
			class="login-form__submit-button button warn"
			type="submit"
			[pngcLoading]="isLoading$ | async"
		>
			Sign in
		</button>
	</div>

	<footer class="login-form__footer">
		Powered by
		<img
			src="/assets/main-logo.png"
			alt="Pangea"
		/>
	</footer>
</form>
<div class="login-background"></div>
